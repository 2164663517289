<template>
  <v-app-bar color="primary" id="toolbar" clipped-left fixed dark app>
    <v-app-bar-nav-icon
      @click.stop="$store.dispatch('setDrawer', !$store.getters.drawer)"
    ></v-app-bar-nav-icon>
    <v-toolbar-title class="hidden-xs-only mr-5">
      <router-link
        :to="{
          name: 'editPage',
          params: { id: selectedWebsite.DashboardPageId.$oid },
        }"
        v-if="logo"
      >
        <img
          class="logo"
          :src="serverUrl + '/images/' + logo + '?token=' + userToken"
        />
      </router-link>
      <router-link
        :to="{
          name: 'editPage',
          params: { id: selectedWebsite.DashboardPageId.$oid },
        }"
        v-else
      >
        <img contain class="logo" :src="getLogo" @error="failedImage = true" />
      </router-link>
    </v-toolbar-title>

    <div class="d-flex align-center" style="margin-left: auto">
      <v-btn
        depressed
        icon
        @click.stop="$store.dispatch('setSearchDrawer', true)"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-menu
        bottom
        :close-on-content-click="false"
        transition="slide-y-transition"
        offset-y
        :nudge-top="-10"
        v-model="headerMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense nav class="pa-3">
          <v-list-item-group color="activemenucolor" v-model="selectedPage">
            <v-list-item
              @click="headerMenu = false"
              to="/websitesoverview"
              v-if="userIsAdministrator"
            >
              <v-layout align-center>
                <v-icon class="mr-4">mdi-laptop</v-icon>
                <p class="mb-0">{{ $t("Modules") }}</p>
              </v-layout>
            </v-list-item>
            <v-list-item
              @click="headerMenu = false"
              to="/usersoverview"
              v-if="userIsAdministrator || userIsUserManager"
            >
              <v-layout align-center>
                <v-icon class="mr-4">mdi-account-supervisor-circle</v-icon>
                <p class="mb-0">{{ $t("Users") }}</p>
              </v-layout>
            </v-list-item>
            <v-list-item
              @click="headerMenu = false"
              to="/settings"
              v-if="userIsAdministrator"
            >
              <v-layout align-center>
                <v-icon class="mr-4">mdi-cog</v-icon>
                <p class="mb-0">{{ $t("Settings") }}</p>
              </v-layout>
            </v-list-item>
            <v-divider v-if="userIsAdministrator || userIsUserManager" />
          </v-list-item-group>
          <v-list-item
            :class="userIsAdministrator || userIsUserManager ? 'mt-2' : ''"
          >
            <v-icon class="mr-4">mdi-account-circle</v-icon>
            {{ userName }}
          </v-list-item>
          <v-list-item>
            <languageSelector
              :whiteColors="true"
              :cssProps="'pt-0 max-lenght-lang-selector'"
            />
          </v-list-item>

          <v-list-item class="mt-6 mx-2 px-0">
            <v-btn
              depressed
              elevation="0"
              @click="
                headerMenu = false;
                $emit('logOut');
              "
              :loading="loggingOut"
              color="primaryred"
              class="white--text"
            >
              <v-icon left> mdi-logout </v-icon>
              {{ $t("Logout") }}</v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import languageSelector from "../../shared/LanguageSelector";
import { mapGetters } from "vuex";

export default {
  props: {
    loggingOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo: this.$config.logoNeg,
      userToken: this.$auth.userToken(),
      serverUrl: this.$helpers.getServerUrl(),
      userIsUserManager: this.$auth.userIsUserManager(),
      failedImage: false,
      selectedPage: null,
      user: this.$auth.getUser(),
      headerMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
    }),
    userName() {
      if (!this.user) return "";

      const splitLastName = this.user.LastName.split(" ");
      const firstLetters = splitLastName.map((word) => word[0]);

      const firsLetterOfLastName = firstLetters.join("");
      return this.user.FirstName + " " + firsLetterOfLastName;
    },
    userIsAdministrator() {
      return this.$auth.userIsAdministrator();
    },
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-neg.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-neg.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
  },

  methods: {
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
  components: {
    languageSelector,
  },
};
</script>

<style scoped>
.logo {
  height: 50px;
  margin-top: 5px;
}
</style>